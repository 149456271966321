import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    QueryList,
    ViewChildren,
    SimpleChanges,
} from '@angular/core';
import { DeferrableFilter, Filter, FilterTemplate, GetTemplateValue, GetValue } from '@models/filter-types';
import { forkJoin } from 'rxjs';
import {
    applyFilters,
    loadFilters,
    resetFilters,
    saveFilters,
} from '@shared/helpers/functions/filter-helpers';
import { isEqual } from 'lodash';
import { FilterInputComponent } from 'src/app/shared/components/filters/filter-input/filter-input.component';

@Component({
    selector: 'app-filter-set',
    templateUrl: './filter-set.component.html',
    styleUrls: ['./filter-set.component.scss'],
})
export class FilterSetComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChildren(FilterInputComponent) filterInputs: QueryList<FilterInputComponent>;

    @Input() template: FilterTemplate;
    @Input() dynamicFilters: { [filterName: string]: Filter<unknown> };
    @Input() name = '';
    @Input() isFiltering = false;
    @Input() applyButtonName = 'Get Data';
    @Input() highlightApplyButton = false;
    @Input() showReports = true;
    @Input() areOtherFiltersChanged = false;
    @Input() onDemandFilterNames: string[] = [];
    @Input() lastChanged: unknown;
    @Input() lastApplied: unknown;


    @Output() templateValueChanged = new EventEmitter();

    @Output() filtersLoaded = new EventEmitter();

    @Output() filtersReset = new EventEmitter();

    @Output() areFiltersChanged: EventEmitter<boolean> = new EventEmitter();

    @Output() optionDeleteRequested: EventEmitter<unknown> = new EventEmitter<unknown>();
    properties: Filter<unknown>[];
    dynamicProperties: Filter<unknown>[];

    isLoaded = false;

    isFiltersSame = true;

    constructor() {
    }

    ngOnInit(): void {
        this.properties = Object.keys(this.template)
            .filter(k => this.template[k] instanceof Filter && !this.onDemandFilterNames.includes(k))
            .map(k => this.template[k]);

        const defPropArray = this.properties.filter(p => p instanceof DeferrableFilter && p.isAsync)
            .map(f => (f as DeferrableFilter<unknown, unknown>));

        // load all the deferrable filters and emit the loaded event when they all complete
        forkJoin(
            defPropArray.map(prop => prop.Load()),
        )
            .subscribe(valueArray => {
                valueArray.forEach((value, index) => {
                    defPropArray[index].finishLoad(value);
                });
                this.filtersLoaded.emit({ template: GetTemplateValue(this.template), value: GetValue(this.template) });
                this.isLoaded = true;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.dynamicFilters) {
            this.dynamicProperties = Object.keys(this.dynamicFilters)
                .filter(k => this.dynamicFilters[k] instanceof Filter)
                .map(k => this.dynamicFilters[k]);
        }
        if(!changes.areOtherFiltersChanged?.isFirstChange()){
            this.onFiltersChanged();
        }
    }

    ngAfterViewInit() {
        if (this.highlightApplyButton) {
            this.filterInputs.changes.subscribe(queryList => {
                queryList.toArray().forEach((input) => {
                    input.valueChanged.subscribe(() => {
                        this.onFiltersChanged();
                    });
                });
            });
        }
    }

    onApplyFilters() {
        if (!this.isFiltering) {
            this.isFiltersSame = true;
            this.areFiltersChanged.emit(this.lastChanged && this.lastApplied && !this.isFiltersSame);
            applyFilters<unknown>(this.template, (arg) => this.templateValueChanged.emit(arg));
        }
    }

    onFiltersChanged() {
        this.lastChanged = GetTemplateValue(this.template);
        this.isFiltersSame = isEqual(this.lastChanged, this.lastApplied);
        this.areFiltersChanged.emit(this.lastChanged && this.lastApplied && !this.isFiltersSame);
    }

    onResetFilters() {
        if (!this.isFiltering) {
            this.isFiltering = true;
            resetFilters<unknown>(this.properties, this.template, this.dynamicProperties, (arg) => {
                this.isFiltering = false;
                this.filtersReset.emit(arg);
                this.isLoaded = true;
            }, () => {
                this.isFiltering = false;
                this.filtersReset.emit(null);
            });
        }
    }

    allFiltersSelectedCheck(): boolean {
        let disabled = false;
        if (this.template) {
            Object.keys(this.template).forEach(key => {
                if (!disabled) {
                    if (key === 'dynamicFilters') {
                        Object.keys(this.template[key]).forEach(dynamicKey => {
                            if (!disabled) {
                                disabled = this.filterCheck(this.template[key][dynamicKey]);
                            }
                        });
                    } else if (key === 'dows'){
                        const optionSelected: boolean[] = [];
                        this.template[key].options.forEach(i => {
                            if (i.included) {
                                optionSelected.push(i.included);
                            }
                        });
                        if (optionSelected.length > 0) {
                            disabled = false;
                        } else {
                            disabled = true;
                        }
                    } else {
                        disabled = this.filterCheck(this.template[key]);
                    }
                }
            });
        }
        return !(disabled || this.isFiltering);
    }

    filterCheck(filter): boolean {
        let disable = false;
        if (filter instanceof Filter && filter.isRequired && !filter.hide) {
            const emptyValues = filter.Value == null || filter.Value.length === 0;
            const filterIsLoading = filter instanceof DeferrableFilter && filter.isLoading;
            if (emptyValues || filterIsLoading) {
                disable = true;
            }
        }
        return disable;
    }

    onSaveFilters() {
        saveFilters(this.template);
    }

    onLoadFilters() {
        loadFilters(this.template);
    }

    openDeleteOptionConfirmation(option) {
        this.optionDeleteRequested.emit(option);
    }
}
