import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    LookupItem,
    Logo,
    StationItem,
    ChannelItem,
    SegmentItem,
    Segment,
    PricingTier,
    StringLookupItem,
    Period,
    ApiPeriod,
    SavedFilter,
    BooleanLookupItem,
    DOWLookupItem,
    CustomDaypart,
    HourLookupItem,
    DaypartLookupItem,
    AdvertiserInsightsFilterParams,
    MarketItem,
    Grid,
    RateExpirationDate,
    PoliticalWindowItem,
    PoliticalTierItem,
    PCodeMarketItem,
    PCodeStationItem,
    PCodeChannelItem,
    ModuleConfig,
    StationExceptionRow,
    Quarter,
    PoliticalInitializationTiers,
    RevenueCodeItem,
    OrderTypeItem, HttpParams, SavedReport, RateCardPeriod,
    PoliticalInitializationTiersConfiguration, PcodePrimaryTierMap, SegmentDOWLookupItem,
    ReportAccess,
    PriorityCodeTiersQuarter,
} from '@models/lookup';
import { forkJoin, Observable, of } from 'rxjs';
import { AsOfDate,
    EditSegmentNameParams,
    MetricsOrderTypedItem,
    SystemSegment,
    HiatusWeekSelection,
    RateCardMetricAlertCalculations,
    DynamicRateCardApiGrid,
    RateCardMetricRowV2 } from '@models/rate-card/rate-card';
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { Segmentation } from '@models/config';
import { merge } from 'lodash';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { generateCachedGet } from '@shared/helpers/functions/helpers';
import { calculateRateCardAlerts2, dynamicRateCardCopyCell } from '@shared/helpers/functions/rate_card_helpers';
import { FilterSettings } from '@models/filter';
import { GlobalCustomDayPartRow, PoliticalWindowRow } from '@models/admin/admin';

@Injectable({
    providedIn: 'root',
})
export class LookupV2Service {

    cacheData: { [url: string]: Observable<unknown> } = {};
    private politicalTierCache$: Observable<LookupItem[]> | null = null;

    constructor(
        private http: HttpClient,
        private httpBackend: HttpBackend,
    ) {
    }

    getPeriods(screenName: string = UrlStore.screenNames.rateCard, tab: number = null,
               channelGroup: number = null, clearCache: boolean = false): Observable<Period[]> {
        let periodsUrl = UrlStore.api.lookupsV2.period + `?screenName=${screenName}`;
        if (tab) {
            periodsUrl += `&tab=${tab}`;
        }
        if (channelGroup) {
            periodsUrl += `&channelGroup=${channelGroup}`;
        }

        return this.generateCachedGet<{ id: number; name: string; startDates: string[]; endDates: string[] }>(
            clearCache,
            periodsUrl,
        ).pipe(switchMap((periods: ApiPeriod[]) => {
            const returnPeriods: Period[] = [];
            periods.forEach((period) => {
                period.startDates.sort((a, b) => a > b ? 1 : -1);
                period.endDates.sort((a, b) => a > b ? 1 : -1);
                const mappedPeriod: Period = {
                    id: period.id,
                    name: period.name,
                    startDateMap: {},
                    endDateMap: {},
                    startDates: period.startDates,
                    endDates: period.endDates,
                    maxQueryCount: period.maxQueryCount,
                    averageDaysPer: period.averageDaysPer,
                    isPolitical: period.isPolitical,
                    isDefault: period.isDefault,
                };
                period.startDates.forEach(date => {
                    const split = date.split('-');
                    const temp2 = { [Number(split[0])]: { [Number(split[1])]: { [Number(split[2])]: date }}};
                    mappedPeriod.startDateMap = merge(mappedPeriod.startDateMap, temp2);
                });
                period.endDates.forEach(date => {
                    const split = date.split('-');
                    const temp2 = { [Number(split[0])]: { [Number(split[1])]: { [Number(split[2])]: date }}};
                    mappedPeriod.endDateMap = merge(mappedPeriod.endDateMap, temp2);
                });

                returnPeriods.push(mappedPeriod);
            });
            return of(returnPeriods);
        }));
    }

    getAdvertiserInsightsAdvertisers(filterParams: AdvertiserInsightsFilterParams,
                                     clearCache: boolean = false): Observable<StringLookupItem[]> {
        return this.generateCachedGet<StringLookupItem>(
            clearCache,
            UrlStore.api.advertiserInsightsLookups.advertisers,
            filterParams,
            false,
        );
    }

    getAdvertiserInsightsAdvertisersCount(filterParams?: AdvertiserInsightsFilterParams,
                                          clearCache: boolean = false): Observable<number> {
        return this.generateCachedGetNoArray<number>(
            clearCache,
            UrlStore.api.advertiserInsightsLookups.advertisersCount,
            filterParams,
            false,
        );
    }

    getAdvertiserInsightsAgencies(filterParams: AdvertiserInsightsFilterParams,
                                  clearCache: boolean = false): Observable<StringLookupItem[]> {
        return this.generateCachedGet<StringLookupItem>(clearCache, UrlStore.api.advertiserInsightsLookups.agencies, filterParams, false);
    }

    getNoChargeOptions(): Observable<LookupItem[]> {
        return of([
            { id: 1, name: 'Include on Separate Row' },
            { id: 2, name: 'Include' },
            { id: 3, name: 'Exclude' },
        ]);
    }

    getOrderDetailOptions(): Observable<LookupItem[]> {
        return this.http.get<LookupItem[]>(UrlStore.api.advertiserInsightsLookups.orderDetailOptions);
    }

    getPricingTiers(channelIds: number[] = null, clearCache: boolean = false): Observable<PricingTier[]> {
        const callUrl = (channelIds ?
            UrlStore.api.lookupsV2.pricingTiers.concat(`?channels=${channelIds.join(',')}`) : UrlStore.api.lookupsV2.pricingTiers);
        return this.generateCachedGet<PricingTier>(clearCache, callUrl);
    }

    getDaysOfWeek(clearCache: boolean = false): Observable<BooleanLookupItem[]> {
        return this.generateCachedGet<BooleanLookupItem>(clearCache, UrlStore.api.lookupsV2.dow);
    }

    getRateCardSpecialEventTypes(daypartIds: number[], spotTypeIds: number[]): Observable<LookupItem[]> {
        const url = UrlStore.api.lookupsV2.specialEventTypes
            .concat(`?daypartIds=${daypartIds.join(',')}`)
            .concat(`&spotTypeIds=${spotTypeIds}`);
        return this.http.get<LookupItem[]>(url);
    }

    getRateCardPeriods(date: string): Observable<RateCardPeriod[]> {
        return this.http.get<RateCardPeriod[]>(UrlStore.api.lookupsV2.rateCardPeriods+`?date=${date}`);
    }

    createSpecialEventType(type: string): Observable<{ isNew: boolean; item: LookupItem }> {
        const body = { specialEventType: type };
        return this.http.post<{ isNew: boolean; item: LookupItem }>(UrlStore.api.specialEventsV2.specialEventType, body);
    }

    updateRateCardSpecialEventType(cardId: string, eventId: number) {
        let body = {
            rateCardId: cardId,
        };
        if (eventId) {
            body = { ...body, ...{ specialEventId: eventId }};
        }
        return this.http.put(UrlStore.api.specialEventsV2.specialEventType, body);
    }

    getPriorityCodeTiers(channelIds: number[]): Observable<PcodePrimaryTierMap[]> {
        const url = UrlStore.api.rateCardLookupsV2.priorityCodeTiers
            .concat(`?channels=${channelIds.join(',')}`);
        return this.http.get<PcodePrimaryTierMap[]>(url);
    }

    getPriorityCodeTiersQuarters(channelIds: number[]): Observable<PriorityCodeTiersQuarter[]> {
        const url = UrlStore.api.admin.priorityCodeTiersQuarters
            .concat(`?channels=${channelIds.join(',')}`);
        return this.http.get<PriorityCodeTiersQuarter[]>(url);
    }

    getRateCardQuarters(checkAvailableRates = false, checkAvailablePcodeRates = false,
                        channelIds: number[] = []): Observable<Quarter[]> {
        const url = UrlStore.api.rateCardLookupsV2.quarters
            .concat(`?checkAvailableRates=${checkAvailableRates}`)
            .concat(`&checkAvailablePcodeRates=${checkAvailablePcodeRates}`)
            .concat(channelIds.length > 0 ? `&channels=${channelIds.join(',')}` : '');
        return this.http.get<Quarter[]>(url);
    }

    getRateCardSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation?: Segmentation,
        filterValues: { [k: string]: number[] } = {},
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getSegments(channelIds, daypartIds, segmentation, filterValues, clearCache, UrlStore.api.rateCardLookupsV2.segments);
    }

    getRateCardAddRowPopupSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation?: Segmentation,
        filterValues: { [k: string]: number[] } = {},
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getSegments(
            channelIds,
            daypartIds,
            segmentation,
            filterValues,
            clearCache,
            UrlStore.api.rateCardLookupsV2.addRowPopupSegments,
        );
    }

    getPoliticalRateCardSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation?: Segmentation,
        filterValues: { [k: string]: number[] } = {},
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getSegments(
            channelIds,
            daypartIds,
            segmentation,
            filterValues,
            clearCache,
            UrlStore.api.rateCardLookupsV2.politicalSegments,
        );
    }

    getDigitalSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation?: Segmentation,
        filterValues: { [k: string]: number[] } = {},
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getSegments(channelIds, daypartIds, segmentation, filterValues, clearCache, UrlStore.api.digital.segments);
    }

    getSpecialEventSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation?: Segmentation,
        filterValues: { [k: string]: number[] } = {},
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getSegments(channelIds, daypartIds, segmentation, filterValues, clearCache, UrlStore.api.specialEventsV2.segments);
    }

    getPoliticalLurSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation?: Segmentation,
        filterValues: { [k: string]: number[] } = {},
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getSegments(channelIds, daypartIds, segmentation, filterValues, clearCache, UrlStore.api.politicalLURV2.segments);
    }

    getInventoryInsightsSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation?: Segmentation,
        filterValues: { [k: string]: number[] } = {},
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getSegments(
            channelIds, daypartIds, segmentation, filterValues, clearCache, UrlStore.api.inventoryInsightsLookups.segments,
        );
    }

    getDashboardScenarioSegments(
        channelIds: number[],
        revenueCategoryIds: number[],
        segmentTye?: string,
        clearCache = false,
    ): Observable<SegmentItem[]> {
        return this.getScenarioSegments(channelIds, revenueCategoryIds, segmentTye, clearCache, UrlStore.api.rateCardLookupsV2.segments);
    }

    getRateGenSpotLengths(channelGroup: number, spotTypeIDs: number[]): Observable<LookupItem[]> {
        let url = UrlStore.api.rateGen.spotLengths;
        if (channelGroup) {
            url += `?channelGroup=${channelGroup}`;
        }
        if (spotTypeIDs.length) {
            url += `&spotType=${spotTypeIDs.join(',')}`;
        }
        return this.http.get<LookupItem[]>(url);
    }

    getPoliticalRateCardSpotLengths(clearCache: boolean = false): Observable<LookupItem[]> {
        const url = UrlStore.api.rateCard.politicalRateCardSpotLengths;
        return this.getSpotLengths(clearCache, url);
    }

    getPoliticalLurSpotLengths(clearCache: boolean = false): Observable<LookupItem[]> {
        return this.getSpotLengths(clearCache, UrlStore.api.politicalLURV2.spotLengths);
    }

    getChannelGroups(clearCache = false,
                     hasDynamicRates = false,
                     hasPoliticalRates = false,
                     hasDigitalRates = false,
                     tabNumber = null): Observable<LookupItem[]> {
        let url = UrlStore.api.lookupsV2.channelGroups;
        if (hasDynamicRates) {
            url += '?hasDynamicRates=true';
            if (tabNumber) {
                url += `&tabNumber=${tabNumber}`;
            }
        } else if (hasPoliticalRates) {
            url += '?hasPoliticalRates=true';
        } else if (hasDigitalRates) {
            url += '?hasDigitalRates=true';
        }
        return this.generateCachedGet<LookupItem>(clearCache, url);
    }

    getMarkets(clearCache = false,
               hasDynamicRates = false,
               hasPoliticalRates = false,
               hasDigitalRates = false,
               tabNumber = null): Observable<MarketItem[]> {
        let url = UrlStore.api.lookupsV2.markets;
        if (hasDynamicRates) {
            url += '?hasDynamicRates=true';
            if (tabNumber) {
                url += `&tabNumber=${tabNumber}`;
            }
        } else if (hasPoliticalRates) {
            url += '?hasPoliticalRates=true';
        } else if (hasDigitalRates) {
            url += '?hasDigitalRates=true';
        }
        return this.generateCachedGet<MarketItem>(clearCache, url);
    }

    getPCodeMarkets(clearCache = false, hasDynamicRates = false): Observable<PCodeMarketItem[]> {
        let url = UrlStore.api.lookupsV2.pCodeMarkets;
        if (hasDynamicRates) {
            url += '?hasDynamicRates=true';
        }
        return this.generateCachedGet<PCodeMarketItem>(clearCache, url);
    }

    getStations(clearCache = false,
                hasDynamicRates = false,
                hasPoliticalRates = false,
                hasDigitalRates = false,
                tabNumber=null): Observable<StationItem[]> {
        let url = UrlStore.api.lookupsV2.stations;
        if (hasDynamicRates) {
            url += '?hasDynamicRates=true';
            if (tabNumber) {
                url += `&tabNumber=${tabNumber}`;
            }
        } else if (hasPoliticalRates) {
            url += '?hasPoliticalRates=true';
        } else if (hasDigitalRates) {
            url += '?hasDigitalRates=true';
        }
        return this.generateCachedGet<StationItem>(clearCache, url);
    }

    getPCodeStations(clearCache = false, hasDynamicRates = false): Observable<PCodeStationItem[]> {
        let url = UrlStore.api.lookupsV2.pCodeStations;
        if (hasDynamicRates) {
            url += '?hasDynamicRates=true';
        }
        return this.generateCachedGet<PCodeStationItem>(clearCache, url);
    }

    getChannels(clearCache = false,
                hasDynamicRates = false,
                hasPoliticalRates = false,
                hasDigitalRates = false,
                tabNumber = null): Observable<ChannelItem[]> {
        let url = UrlStore.api.lookupsV2.channels;
        if (hasDynamicRates) {
            url += '?hasDynamicRates=true';
            if (tabNumber) {
                url += `&tabNumber=${tabNumber}`;
            }
        } else if (hasPoliticalRates) {
            url += '?hasPoliticalRates=true';
        } else if (hasDigitalRates) {
            url += '?hasDigitalRates=true';
        }
        return this.generateCachedGet<ChannelItem>(clearCache, url);
    }
    getPCodeChannels(clearCache = false, hasDynamicRates = false): Observable<PCodeChannelItem[]> {
        let url = UrlStore.api.lookupsV2.pCodeChannels;
        if (hasDynamicRates) {
            url += '?hasDynamicRates=true';
        }
        return this.generateCachedGet<ChannelItem>(clearCache, url);
    }

    getPriorityCodes(channelGroup: number): Observable<LookupItem[]> {
        let url = UrlStore.api.lookupsV2.priorityCodes;
        if (channelGroup) {
            url += `?channelGroup=${channelGroup}`;
        }
        return this.http.get<LookupItem[]>(url);
    }

    getDayparts(channelIds: number[], spotTypeIds: number[], filterSettings: FilterSettings): Observable<DaypartLookupItem[]> {
        let reqUrl = UrlStore.api.lookupsV2.dayparts
            .concat(`?channelIds=${channelIds.join(',')}`);
        if (spotTypeIds.length > 0) {
            reqUrl += `&spotTypes=${spotTypeIds.join(',')}`;
        }
        if (filterSettings.hasDynamicRates) {
            reqUrl += '&hasDynamicRates=true';
            if (filterSettings.tabNumber) {
                reqUrl += `&tab=${filterSettings.tabNumber}`;
            }
        } else if (filterSettings.hasPoliticalRates) {
            reqUrl += '&hasPoliticalRates=true';
        } else if (filterSettings.hasDigitalRates) {
            reqUrl += '&hasDigitalRates=true';
        } else if (filterSettings.hasLURData) {
            reqUrl += '&hasLURData=true';
        }
        if (filterSettings.includeCustomDayparts) {
            reqUrl += '&includeCustom=true';
        }
        if (filterSettings.useCanonicalName) {
            reqUrl += '&useCanonicalName=true';
        }
        if (filterSettings.isRateGen) {
            reqUrl += '&isRateGen=true';
        }
        return this.http.get<DaypartLookupItem[]>(reqUrl);
    }

    addCustomDaypart(newCustomDaypart: CustomDaypart): Observable<CustomDaypart> {
        return this.http.put<CustomDaypart>(UrlStore.api.customDaypart.customDaypart, newCustomDaypart);
    }

    getCustomDaypart(): Observable< Grid<GlobalCustomDayPartRow>> {
        return this.http.get< Grid<GlobalCustomDayPartRow>>(UrlStore.api.customDaypart.customDaypartAll);
    }


    deleteCustomDaypartById(customDaypartId: unknown): Observable<unknown> {
        return this.http.delete<CustomDaypart>(UrlStore.api.customDaypart.customDaypart + `/${customDaypartId}`);
    }

    deleteCustomDaypartAccessForUser(customDaypartId: unknown): Observable<unknown> {
        return this.http.delete<CustomDaypart>(UrlStore.api.customDaypart.customDaypartAccessForUser + `/${customDaypartId}`);
    }

    getSpotTypes(channelGroup: number,
                 channelIds: number[],
                 filterSettings: FilterSettings): Observable<LookupItem[]> {
        let url = UrlStore.api.lookupsV2.spotTypes;

        if (filterSettings.hasDynamicRates) {
            url += '?hasDynamicRates=true';
            if (filterSettings.tabNumber) {
                url += `&tab=${filterSettings.tabNumber}`;
            }
        } else if (filterSettings.hasPoliticalRates) {
            url += '?hasPoliticalRates=true';
        } else if (filterSettings.hasDigitalRates) {
            url += '?hasDigitalRates=true';
        }
        if (filterSettings.isRateGen) {
            url += `&isRateGen=true`;
        }
        if (channelGroup) {
            url += `&channelGroup=${channelGroup}`;
        }
        if (channelIds.length) {
            url += `&channels=${channelIds.join(',')}`;
        }
        return this.http.get<LookupItem[]>(url);
    }

    getAsOfDates(): Observable<AsOfDate> {
        return this.http.get<AsOfDate>(UrlStore.api.lookupsV2.asOfDatesV2);
    }

    getRateCardModuleConfig(module: string): Observable<ModuleConfig[]> {
        return this.http.get<ModuleConfig[]>(UrlStore.api.rateCardLookupsV2.moduleConfig +`?module=${module}`);
    }

    getPoliticalTiers(clearCache: boolean = false): Observable<LookupItem[]> {
        if (!this.politicalTierCache$ || clearCache) {
            this.politicalTierCache$ = this.http
                .get<LookupItem[]>(UrlStore.api.admin.politicalTiers)
                .pipe(shareReplay(1));
        }

        return this.politicalTierCache$;
    }

    getPoliticalBoundOptions(): Observable<PoliticalTierItem[]> {
        return this.http.get<PoliticalTierItem[]>(
            UrlStore.api.internalAdmin.politicalBoundConfigOptions,
        );
    }

    getRevenueCodes(): Observable<RevenueCodeItem[]> {
        return this.http.get<RevenueCodeItem[]>(
            UrlStore.api.admin.revenueCodes,
        );
    }

    getOrderType(): Observable<OrderTypeItem[]> {
        return this.http.get<OrderTypeItem[]>(
            UrlStore.api.admin.orderType,
        );
    }

    getPoliticalWindows(marketIds: number[], includeInitStrategy: boolean = false, removePastWindows: boolean = false):
    Observable<PoliticalWindowItem[]> {
        return this.http.get<PoliticalWindowItem[]>(
            UrlStore.api.lookupsV2.politicalWindow + `?marketIds=${marketIds}&includeInitStrategy=${includeInitStrategy}&removePastWindows=${removePastWindows}`,
        );
    }

    getAllPoliticalWindows(): Observable<PoliticalWindowItem[]> {
        return this.http.get<PoliticalWindowItem[]>(
            UrlStore.api.lookupsV2.allPoliticalWindow,
        );
    }

    getQuarterOfDate(date: string): Observable<{ quarter: string }> {
        return this.http.get<{ quarter: string }>(
            UrlStore.api.lookupsV2.quarterOfDate + `?date=${date}`,
        );
    }

    getPoliticalInitAllTiers(channelIds: number[] = []): Observable<PoliticalInitializationTiers[]> {
        return this.http.get<PoliticalInitializationTiers[]>(UrlStore.api.lookupsV2.politicalInitAllTiers + `?channelIds=${channelIds}`);
    }

    getPoliticalInitPoliticalTiers(channelIds: number[] = [], politicalWindowIds: number[] = []):
        Observable<PoliticalInitializationTiersConfiguration[]> {
        const url = UrlStore.api.lookupsV2.politicalInitPoliticalTiers + `?channelIds=${channelIds}&politicalWindows=${politicalWindowIds}`;
        return this.http.get<PoliticalInitializationTiersConfiguration[]>(url);
    }

    generateCachedGet<T>(clearCache: boolean, url: string, params?: HttpParams, useRequestData = true): Observable<T[]> {
        return generateCachedGet<T[]>(clearCache, url, this.http, params, this.cacheData, useRequestData);
    }

    generateCachedGetNoArray<T>(clearCache: boolean, url: string, params?: HttpParams, useRequestData = true): Observable<T> {
        return generateCachedGet<T>(clearCache, url, this.http, params, this.cacheData, useRequestData);
    }

    saveReport(
        screenName: string,
        tabData: number,
        reportData: unknown,
        filter: unknown,
        channels: unknown,
        reportName: string,
        isNewReport: boolean,
        reportId: number,
    ): Observable<void> {
        const url = UrlStore.api.lookupsV2.savedReport.concat(`/${screenName}`);
        let body = {
            is_new_report: isNewReport,
            report_name: reportName,
            screen_name: screenName,
            tab: tabData,
            data: reportData,
            filter,
            channels,
        };
        if (reportId) {
            body = { ...body, ...{ report_id: reportId }};
        }
        return this.http.put<void>(url, body);
    }

    saveEmailReportFrequency(
        emailReportId: number,
        reportId: number,
        frequencyId: number,
        dow: number[],
        time: string,
        weekOfMonth: string[],
        firstDayOfMonth: boolean,
        userIds: number[],
    ): Observable<number> {
        const url = UrlStore.api.lookupsV2.saveEmailReportFrequency;
        const body = {
            report_id: reportId,
            frequency_id: frequencyId,
            ...(emailReportId && { email_report_id: emailReportId }),
            ...(dow && { dow }),
            ...(time && { time }),
            ...(weekOfMonth && { week_of_month: weekOfMonth }),
            ...(firstDayOfMonth && { first_day_of_month: firstDayOfMonth }),
            ...(userIds && { user_ids: userIds }),

        };
        return this.http.put<number>(url, body);
    }

    savePoliticalWindow(
        markets: number[],
        startDate: string,
        endDate: string,
        windowName: string,
    ): Observable<Grid<PoliticalWindowRow>> {
        return this.http.put<Grid<PoliticalWindowRow>>(UrlStore.api.admin.politicalWindows,
                                                       {
                                                           markets,
                                                           startDate,
                                                           endDate,
                                                           windowName,
                                                       });
    }

    toggleSaveReportIsFavorited(screenName: string, reportId: number, isFavorited: boolean): Observable<void> {
        const url = UrlStore.api.lookupsV2.savedReport.concat(`/${screenName}`);
        return this.http.post<void>(url, {
            report_id: reportId,
            is_favorited: isFavorited,
        });
    }

    deleteSavedReport(screenName: string, reportId: number): Observable<void> {
        const url = UrlStore.api.lookupsV2.savedReport.concat(`/${screenName}?report_id=${reportId}`);
        return this.http.delete<never>(url);
    }

    userSaveReportAccess(screenName: string, reportsAccessData: ReportAccess[]): Observable<void> {
        const url = UrlStore.api.lookupsV2.userSavedReportAccess.concat(`/${screenName}`);
        return this.http.post<void>(url, { data: reportsAccessData });
    }

    loadSavedReports(screenName: string, tab: number): Observable<SavedReport[]> {
        const url = UrlStore.api.lookupsV2.savedReport.concat(`/${screenName}?tab=${tab}`);
        return this.http.get<SavedReport[]>(url);
    }

    getSavedReport(reportId: number): Observable<SavedReport[]> {
        const url = UrlStore.api.lookupsV2.savedReport.concat(`?reportId=${reportId}`);
        return this.http.get<SavedReport[]>(url);
    }

    getFilters(screenName: string): Observable<SavedFilter[]> {
        return this.http.get<SavedFilter[]>(UrlStore.api.lookupsV2.filters.concat(`/${screenName}`));
    }

    saveFilterToLocalStorage(filterName: string, filter: unknown): void {
        localStorage.setItem(filterName, JSON.stringify(filter));
    }

    getFilterFromLocalStorage(filterName: string): string[] {
        return JSON.parse(localStorage.getItem(filterName)) || [];
    }

    getTypedMetricsOrderFromLocalStorage(filterName: string): MetricsOrderTypedItem[] {
        return JSON.parse(localStorage.getItem(filterName)) || [];
    }


    getColumnSortOrderFromLocalStorage<T>(filterName: string): T {
        return JSON.parse(localStorage.getItem(filterName));
    }
    getCustomDaypartDOW(): DOWLookupItem[] {
        return [
            {
                name: 'M-F',
                mask: {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false,
                    sunday: false,
                },
            },
            {
                name: 'Sa-Su',
                mask: {
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: true,
                    sunday: true,
                },
            },
            {
                name: 'M-Su',
                mask: {
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                },
            },
        ];
    }

    getSegmentDOW(): Observable<SegmentDOWLookupItem[]> {
        return of([
            { name: 'Mon', ids: [0]},
            { name: 'Tues', ids: [1]},
            { name: 'Wed',ids: [2]},
            { name: 'Thu',ids: [3]},
            { name: 'Fri',ids: [4]},
            { name: 'Sat',ids: [5]},
            { name: 'Sun',ids: [6]},
            { name: 'Mon-Fri',ids: [0,1,2,3,4]},
            { name: 'Mon-Sun',ids: [0,1,2,3,4,5,6]},
            { name: 'Sat-Sun',ids: [5,6]},
        ]);
    }

    getCustomDaypartHours(): HourLookupItem[] {
        return [
            { id: 1, value: 0, displayValue: '12a', startTime: '00:00:00', endTime: '00:00:00' },
            { id: 2, value: 1, displayValue: '1a', startTime: '01:00:00', endTime: '01:00:00' },
            { id: 3, value: 2, displayValue: '2a', startTime: '02:00:00', endTime: '02:00:00' },
            { id: 4, value: 3, displayValue: '3a', startTime: '03:00:00', endTime: '03:00:00' },
            { id: 5, value: 4, displayValue: '4a', startTime: '04:00:00', endTime: '04:00:00' },
            { id: 6, value: 5, displayValue: '5a', startTime: '05:00:00', endTime: '05:00:00' },
            { id: 7, value: 6, displayValue: '6a', startTime: '06:00:00', endTime: '06:00:00' },
            { id: 8, value: 7, displayValue: '7a', startTime: '07:00:00', endTime: '07:00:00' },
            { id: 9, value: 8, displayValue: '8a', startTime: '08:00:00', endTime: '08:00:00' },
            { id: 10, value: 9, displayValue: '9a', startTime: '09:00:00', endTime: '09:00:00' },
            { id: 11, value: 10, displayValue: '10a', startTime: '10:00:00', endTime: '10:00:00' },
            { id: 12, value: 11, displayValue: '11a', startTime: '11:00:00', endTime: '11:00:00' },
            { id: 13, value: 12, displayValue: '12p', startTime: '12:00:00', endTime: '12:00:00' },
            { id: 14, value: 13, displayValue: '1p', startTime: '13:00:00', endTime: '13:00:00' },
            { id: 15, value: 14, displayValue: '2p', startTime: '14:00:00', endTime: '14:00:00' },
            { id: 16, value: 15, displayValue: '3p', startTime: '15:00:00', endTime: '15:00:00' },
            { id: 17, value: 16, displayValue: '4p', startTime: '16:00:00', endTime: '16:00:00' },
            { id: 18, value: 17, displayValue: '5p', startTime: '17:00:00', endTime: '17:00:00' },
            { id: 19, value: 18, displayValue: '6p', startTime: '18:00:00', endTime: '18:00:00' },
            { id: 20, value: 19, displayValue: '7p', startTime: '19:00:00', endTime: '19:00:00' },
            { id: 21, value: 20, displayValue: '8p', startTime: '20:00:00', endTime: '20:00:00' },
            { id: 22, value: 21, displayValue: '9p', startTime: '21:00:00', endTime: '21:00:00' },
            { id: 23, value: 22, displayValue: '10p', startTime: '22:00:00', endTime: '22:00:00' },
            { id: 24, value: 23, displayValue: '11p', startTime: '23:00:00', endTime: '23:00:00' },
        ];
    }

    getRateExpirationDate(channelIds: number[]): Observable<Grid<RateExpirationDate>> {
        let url = UrlStore.api.lookupsV2.rateExpirationDate;
        if (channelIds.length > 0) {
            url += `?channels=${channelIds}`;
        }
        return this.http.get<Grid<RateExpirationDate>>(url);
    }

    getLogos(color: 'white' | 'black' | 'sassblue' | 'analyticsblue' = 'black'): Observable<{ raLogo: Blob; productLogo: Blob }> {
        const raLogo = this.http.get(`/assets/images/ra-logo-${color}.png`, { responseType: 'blob' });
        const productLogo = this.http.get(`/assets/images/rate-optics-logo-${color}.png`, { responseType: 'blob' });
        return forkJoin({ raLogo, productLogo }).pipe(map(resp => resp));
    }

    getCustomerLogo(): Observable<Blob> {
        return this.http.get<string>(UrlStore.api.lookupsV2.customerLogo)
            .pipe(switchMap((url: string) => new HttpClient(this.httpBackend)
                .get(
                    url, { responseType: 'blob' },
                )));
    }

    getLogo(color: 'white' | 'black' | 'sassblue' | 'analyticsblue' = 'black'): Observable<Logo> {
        const img = new Image();
        img.src = `assets/images/rate-optics-logo-${color}.png`;
        return of({ image: img, height: color === 'black' ? 270 : 974, width: color === 'black' ? 1366 : 6280 });
    }

    autoPublish(channelIds: number[], autoPublish: boolean): Observable<unknown> {
        return this.http.post(UrlStore.api.lookupsV2.autoPublish, { channelIds, autoPublish });
    }

    getSystemSegments(channelId: number, segmentId: number, segmentType: string): Observable<SystemSegment> {
        return this.http.get<SystemSegment>(UrlStore.api.rateCardLookupsV2.systemSegments + `?channelId=${channelId}&segmentId=${segmentId}&segmentType=${segmentType}` );
    }

    saveSystemSegmentEdits(
        edits: {
            channelId: number;
            segmentId: number;
            segmentType: string;
            segmentName: string;
        }[],
    ): Observable<EditSegmentNameParams[]> {
        return this.http.post<EditSegmentNameParams[]>(UrlStore.api.rateCardLookupsV2.systemSegments, { edits });
    }

    getSystemSegmentHiatusWeeks(
        channelId: number,
        baseSegmentationId: number,
        dynamicSegmentation,
        daypartId: number,
        spotTypeId: number,
    ): Observable<HiatusWeekSelection[]> {
        return this.http.post<HiatusWeekSelection[]>(UrlStore.api.rateCardLookupsV2.systemSegmentsAiredWeeks,
                                                     { channelId, baseSegmentationId, dynamicSegmentation, daypartId, spotTypeId });
    }

    saveSystemSegmentHiatusWeeks(
        channelId: number,
        baseSegmentationId: number,
        dynamicSegmentation,
        daypartId: number,
        spotTypeId: number,
        hiatusWeeks: string[],
        removedWeeks: string[],
        reloadParams: { startDate: string; endDate: string; period: number },
        rateCardTab: number,
    ): Observable<RateCardMetricRowV2[]> {
        return this.http.post<DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>>(
            UrlStore.api.rateCardLookupsV2.systemSegmentsHiatusWeeks,
            {
                channelId,
                baseSegmentationId,
                dynamicSegmentation,
                daypartId,
                spotTypeId,
                hiatusWeeks,
                removedWeeks,
                reloadParams,
                rateCardTab,
            },
        )
            .pipe(
                switchMap((response: DynamicRateCardApiGrid<RateCardMetricRowV2, RateCardMetricAlertCalculations[]>) =>
                    calculateRateCardAlerts2(
                        response.data,
                        response.alerts,
                        response.groupedColumns,
                        response.primaryTierMap,
                        response.pcodePrimaryTierMap,
                        response.tiers,
                        dynamicRateCardCopyCell,
                    ).pipe(switchMap(data => of(data.data)))),
            );
    }

    public getSegments(
        channelIds: number[],
        daypartIds: number[],
        segmentation: Segmentation,
        filterValues: { [k: string]: number[] },
        clearCache: boolean,
        baseUrl: string,
    ): Observable<SegmentItem[]> {
        let url = `${baseUrl}?segment_type=${segmentation.segment_type}&channelIds=${channelIds.join(',')}`;
        if (daypartIds?.length) {
            url += `&dayparts=${daypartIds.join(',')}`;
        }
        const filters = Object.keys(filterValues).map(k => `${k}=${filterValues[k].join(',')}`).join('&');
        return generateCachedGet<Segment[]>(
            clearCache,
            `${url}&${filters}`,
            this.http,
            undefined,
            this.cacheData,
            false,
        ).pipe(map(segments => segments.map(s => ({
            id: s.segment_id,
            ids: s.segment_ids,
            name: s.segment_display_name ?? s.segment_name,
        }))));
    }

    public clearSegmentCache(): void{
        Object.keys(this.cacheData).forEach(key => {
            if (key.includes('/segments')){
                delete this.cacheData[key];
            }
        });
    }

    public getScenarioSegments(
        channelIds: number[],
        revenueCategoryIds: number[],
        segmentation: string,
        clearCache: boolean,
        baseUrl: string,
    ): Observable<SegmentItem[]> {
        let url = `${baseUrl}?segment_type=${segmentation}&channelIds=${channelIds.join(',')}`;
        if (revenueCategoryIds?.length) {
            url += `&revenueCategoryIds=${revenueCategoryIds.join(',')}`;
        }
        return generateCachedGet<Segment[]>(
            clearCache,
            `${url}`,
            this.http,
            undefined,
            this.cacheData,
            false,
        ).pipe(map(segments => segments.map(s => ({
            id: s.segment_id,
            ids: s.segment_ids,
            name: s.segment_display_name ?? s.segment_name,
        }))));
    }

    public getSpotLengths(clearCache: boolean, baseUrl: string): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(
            clearCache,
            baseUrl,
        );
    }

    public getProducts(channels: number[], productTypeIds: number[] = null, clearCache = false): Observable<LookupItem[]> {
        const callUrl = (productTypeIds
            ?  UrlStore.api.lookupsV2.products.concat(`?productTypes=${productTypeIds.join(',')}&channels=${channels.join(',')}`)
            : UrlStore.api.lookupsV2.products.concat(`?channels=${channels.join(',')}`));
        return this.generateCachedGet<ChannelItem>(clearCache, callUrl);
    }

    public getProductTypes(channelIds: number[], clearCache = false): Observable<LookupItem[]> {
        let url = UrlStore.api.lookupsV2.productTypes;
        url += `?channels=${channelIds}`;
        return this.generateCachedGet<ChannelItem>(clearCache, url);
    }

    getStationExceptions(): Observable<Grid<StationExceptionRow>> {
        return this.http.get<Grid<StationExceptionRow>>(UrlStore.api.lookupsV2.stationExceptions)
            .pipe(switchMap((this.mapStationExceptionGrid)));
    }

    getDigitalPricingTiers(clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookupsV2.digitalPricingTiers);
    }

    getDynamicPricingTiers(clearCache: boolean = false): Observable<LookupItem[]> {
        return this.generateCachedGet<LookupItem>(clearCache, UrlStore.api.lookupsV2.dynamicPricingTiers);
    }

    private mapStationExceptionGrid(grid: Grid<StationExceptionRow>): Observable<Grid<StationExceptionRow>> {
        const mappedGrid: Grid<StationExceptionRow> = {
            columns: grid.columns,
            data: grid.data.map(row => ({
                ...row,
            })),
        };
        return of(mappedGrid);
    }

}
